<template>
  <div
    class="Featured"
    @keyup.left="onKeyUpLeft"
    @keyup.right="onKeyUpRight"
    @keyup.up="onKeyUpUp"
    @keyup.down="onKeyUpDown"
    @touchmove.prevent
  >
    <div class="placement">
      <div class="collection">
        <div class="group"
          ref="group"
          v-for="( group, index ) in collection"
          :key="group.title"
          :style="getMountedPieceDimensions( group.aspect, group.scale, $store.state.viewportWidth, $store.state.viewportHeight )"
        >
          <a
            :href="pieceRoute + group.slug"
            @click.prevent="onGroupClick( index )"
            @mousedown.prevent
            @mousemove.prevent
            @mouseup.prevent="onGroupMouseUp"
            tabindex="-1"
          >
            <img class="piece"
              v-for="( preview, index ) in group.previews"
              v-bind="preview.attr"
              :key="index"
              :class="{'is-active': index === group.activePreviewIndex}"
              :alt="`${group.title} preview image ${index + 1}`"
            />
          </a>
          <MountedPieceShadow ref="shadow" />
        </div>
      </div>
      <transition-group tag="div" mode="out-in" name="title" class="titles">
        <h2
          v-for="( group, index ) in collection"
          :key="group.title"
          v-show="index === activeGroupIndex && isMounted"
        >
          <a
            :href="pieceRoute + group.slug"
            @click.prevent="onGroupClick( index )"
            @mousedown.prevent
            @mousemove.prevent
            @mouseup.prevent="onGroupMouseUp"
          >
            {{group.title}}
          </a>
        </h2>
      </transition-group>
    </div>
    <div class="hint">
      <transition-group tag="p" mode="out-in" name="hint">
        <span v-show="hintLineIndex === 1" key="hint-scroll">{{ hintActionText }}</span>
        <span v-show="hintLineIndex === 2" key="hint-select">Select to inspect</span>
      </transition-group>
    </div>
  </div>
</template>

<script>

import viewport from '@/managers/ViewportManager'
import ticker from '@/managers/TickManager'
import input from '@/managers/InputManager'

import * as Detect from '@/utils/detect'

import routes from '@/data/routes'
import collection from '@/data/collection'

import { getMountedPieceDimensions } from '@/mixins'
import { clamp, sign, modulo } from '@/utils/math'
import { easeInCubic } from '@/utils/easing'

import MountedPieceShadow from '@/components/MountedPieceShadow'

const DRAG_SENSITIVITY = 1.5 // 1 = one-to-one
const DRAG_SENSITIVITY_MOBILE = 2.0
const SCROLL_SENSITIVITY = 0.8
const CLICK_SENSITIVITY = 15 // pixels of movement before ignoring click

export default {
  name: 'Featured',
  mixins: [ getMountedPieceDimensions ],
  props: {
    isMountingFromPreloader: {
      type: Boolean,
      required: true
    }
  },
  components: { MountedPieceShadow },
  data() {
    return {
      isSafari: Detect.safari(),
      isMounted: false,
      collection: collection.map( ( group, index ) => (
        {
          ...group,
          activePreviewIndex: 0,
          visibility: 0,
          x: 0
        }
      )),
      activeGroupIndex: this.$store.state.activeGroupIndex,
      willNavigate: false,
      willIgnoreClick: false,
      hintLineIndex: 0
    }
  },
  computed: {
    pieceRoute() {
      return `/${ routes.PIECE }/`
    },
    hintActionText() {
      const actionName = this.$store.state.isMobile ? 'Swipe' : 'Scroll'
      return `${actionName} to explore`
    }
  },
  mounted() {

    this.scrollTarget = 0
    this.scrollSmoothed = 0
    this.dragTarget = 0
    this.dragSmoothed = 0

    this.prevShiftAmount = 0
    this.shiftAmount = 0
    this.shiftAmountTarget = 0
    this.initialShiftAmount = this.activeGroupIndex * viewport.latest.width

    this.initialEntryOffset = this.isMountingFromPreloader ? 200 : 0 // vh

    this.advanceTimeout = 0

    viewport.refresh()

    this.isMounted = true

    if ( this.isSafari ) {
      document.documentElement.classList.add( 'is-safari-scroll-locked' )
    }

    // Start hinting after 3s
    this.hintingStartTimer = setTimeout( this.startHinting, 2100 )
  },
  activated() {

    this.tickId = ticker.on( this.onTick )
    this.bindKeyNavigation()
  },
  deactivated() {

    ticker.off( this.tickId )
    this.unbindKeyNavigation()
  },
  beforeDestroy() {

    clearTimeout( this.hintingStartTimer )
    this.stopHinting()

    ticker.off( this.tickId )

    if ( this.isSafari ) {
      document.documentElement.classList.remove( 'is-safari-scroll-locked' )
    }
  },
  methods: {
    advanceGroup( groupIndex ) {
      this.collection[ groupIndex ].activePreviewIndex =
        this.willNavigate
          ? 0
          : this.collection[ groupIndex ].activePreviewIndex >= this.collection[ groupIndex ].previews.length - 1
            ? 0
            : this.collection[ groupIndex ].activePreviewIndex += 1
    },
    onTick( { delta, elapsed } ) {

      const fpsDelta = delta / ( 1000 / 60 )

      this.prevShiftAmount = this.shiftAmount

      this.updatePositions( fpsDelta )

      const shiftDelta = Math.abs( this.shiftAmount - this.prevShiftAmount )
      const advanceDuration = 800 / clamp( Math.pow( shiftDelta, 0.5 ), 1, 8 )

      this.updateActivePreview( delta, advanceDuration, this.activeGroupIndex )
    },
    updatePositions( fpsDelta ) {

      const { width, height } = viewport.latest
      const { isDown, speed, wheel } = input.latest.data
      const { isMobile } = this.$store.state

      const groupCount = this.collection.length
      const totalWidth = groupCount * width

      const prevDragTarget = this.dragTarget
      const prevScrollTarget = this.scrollTarget

      this.initialEntryOffset += -this.initialEntryOffset * 0.05
      this.initialEntryOffset = this.initialEntryOffset < 0.0001 ? 0 : this.initialEntryOffset

      this.dragTarget += isDown ? speed.x * width * -1 * ( isMobile ? DRAG_SENSITIVITY_MOBILE : DRAG_SENSITIVITY ) : 0
      this.dragSmoothed += ( this.dragTarget - this.dragSmoothed ) * 0.1

      // Debug: Constantly apply rotation
      // this.scrollTarget += 25

      const MAX_WHEEL_DELTA = 200
      this.scrollTarget += clamp( wheel.deltaY * -1 + wheel.deltaX, -MAX_WHEEL_DELTA, MAX_WHEEL_DELTA ) * SCROLL_SENSITIVITY * fpsDelta
      const scrollSnapSensitivity = isMobile ? 0.14 : 0.1
      this.scrollSmoothed += ( this.scrollTarget - this.scrollSmoothed ) * ( this.willNavigate ? scrollSnapSensitivity : 0.06 )

      if ( Math.abs( this.dragTarget - this.dragSmoothed ) < 1 &&
           Math.abs( this.scrollTarget - this.scrollSmoothed ) < 1 ) {
        this.$store.commit( 'setWheeling', false )
      }
      else {
        this.$store.commit( 'setWheeling', true )
      }

      // Unflag navigation if targets have changed
      if ( Math.abs( this.dragTarget - prevDragTarget ) > 1 || Math.abs( this.scrollTarget - prevScrollTarget ) > 1 ) {
        this.willNavigate = false
      }

      // Recycle displacement across whole collection
      this.shiftAmount = modulo( this.initialShiftAmount + this.dragSmoothed + this.scrollSmoothed, totalWidth )
      this.shiftAmountTarget = modulo( this.initialShiftAmount + this.dragTarget + this.scrollTarget, totalWidth )

      // Update hinting as now user has scrolled at least 1/2 of viewport width
      if ( !this.$store.state.hintingHasScrolled && ( Math.abs( this.dragTarget ) + Math.abs( this.scrollTarget ) ) > width * 0.5 ) {
        this.$store.commit( 'setHintingHasScrolled' )
      }

      this.$refs.group.forEach( ( el, index ) => {

        const group = this.collection[ index ]
        const shadow = this.$refs.shadow[ index ].$el

        group.x = index * width + this.shiftAmount * -1

        // Recycle this group within the viewport
        if ( group.x <= width * -1 ) group.x += totalWidth

        const viewportIntersection = ( width - group.x ) / width
        const intersectionSide = sign( 1 - viewportIntersection )
        group.visibility = 1 - clamp( Math.abs( 1 - viewportIntersection ), 0, 1 )

        if ( group.visibility > 0.5 ) this.updateActiveGroup( index )

        const initialEntryOffsetY = index === 0 ? this.initialEntryOffset : 0

        const translateX = group.x
        const translateY = height * 0.013 * easeInCubic( Math.abs( 1 - group.visibility ) ) + initialEntryOffsetY
        const rotate = easeInCubic( Math.abs( 1 - group.visibility ) ) * 10 * intersectionSide

        el.style.transform = `translate3d( ${ translateX }px, ${ translateY }vh, 0 ) rotate( ${ rotate }deg ) scale(${0.995 + 0.005 * (1 - group.visibility)})`

        const shadowPanX = 0.667 + -5 * Math.abs( 1 - group.visibility ) * intersectionSide
        const shadowPanY = 1.5 + 5 * easeInCubic( Math.abs( 1 - group.visibility ) )
        const shadowRotate = rotate * 0.25
        const shadowOpacity = easeInCubic( Math.abs( 1 - group.visibility ) )

        shadow.style.transform = `translate3d( ${ shadowPanX }vw, ${ shadowPanY }vw, 0 ) rotate( ${ shadowRotate }deg ) scale(${0.995 + 0.005 * (1 - group.visibility)})`
        shadow.style.opacity = 0.13 + 0.07 * shadowOpacity
      } )

      const navigationSensitivity = isMobile ? 0.0004 : 0.0002
      if ( this.willNavigate && this.collection[ this.activeGroupIndex ].visibility > ( 1 - navigationSensitivity ) ) {
        this.$store.commit( 'setHintingHasSelected' )
        this.routeToPiece()
      }
    },
    updateActiveGroup( index ) {

      this.activeGroupIndex = index
    },
    updateActivePreview( delta, period, groupIndex ) {

      this.advanceTimeout += delta

      if ( this.advanceTimeout >= period ) {
        this.advanceGroup( groupIndex )
        this.advanceTimeout = 0
      }
    },
    onGroupMouseUp() {

      // Flag clicks to be ignored if cursor moved too far (i.e. dragging)
      this.willIgnoreClick = Math.abs( input.latest.data.travel.x ) > CLICK_SENSITIVITY
    },
    onGroupClick( index ) {

      if ( this.willIgnoreClick ) {
        this.willIgnoreClick = false
      }
      else {
        this.jumpToGroup( index )
        this.willNavigate = true
      }
    },
    jumpToGroup( index ) {

      const { width } = viewport.latest
      const totalWidth = this.collection.length * width

      const offset = ( index * width ) - this.shiftAmountTarget

      // Circling back aroud from end to start
      if ( offset < -width ) {
        this.scrollTarget += totalWidth + offset
      }
      else {
        this.scrollTarget += offset
      }
    },
    jumpToNextGroup() {
      const { width } = viewport.latest
      this.scrollTarget += width - modulo( this.shiftAmountTarget, width )
    },
    jumpToPrevGroup() {
      const { width } = viewport.latest
      const shift = width - ( width - modulo( this.shiftAmountTarget, width ) )
      this.scrollTarget -= shift === 0 ? shift + width : shift
    },
    routeToPiece() {

      this.willNavigate = false
      this.$router.push( { name: routes.PIECE, params: { slug: this.collection[ this.activeGroupIndex ].slug } } )
    },
    bindKeyNavigation() {
      window.addEventListener( 'keyup', this.onKeyUp, false )
    },
    unbindKeyNavigation() {
      window.removeEventListener( 'keyup', this.onKeyUp, false )
    },
    onKeyUp( e ) {
      switch ( e.which ) {
        case 37:
          this.onKeyUpLeft()
          break
        case 39:
          this.onKeyUpRight()
          break
        case 38:
          this.onKeyUpUp()
          break
        case 40:
          this.onKeyUpDown()
          break
        case 13:
          this.onKeyUpEnter()
          break
      }
    },
    onKeyUpLeft() {
      this.jumpToPrevGroup()
    },
    onKeyUpRight() {
      this.jumpToNextGroup()
    },
    onKeyUpUp() {
      this.jumpToPrevGroup()
    },
    onKeyUpDown() {
      this.jumpToNextGroup()
    },
    onKeyUpEnter() {
      this.jumpToGroup( this.activeGroupIndex )
      this.willNavigate = true
    },
    startHinting() {
      this.updateHint()
      this.hintInterval = setInterval( this.updateHint, 600 )
    },
    updateHint() {

      // Both actions complete, stop hinting
      if ( this.$store.state.hintingHasScrolled && this.$store.state.hintingHasSelected ) {
        this.stopHinting()
      }
      // Has scrolled, but not selected
      else if ( this.$store.state.hintingHasScrolled ) {
        this.hintLineIndex = this.hintLineIndex === 0 ? 2 : 0
      }
      // Has selected, but not scrolled (e.g. if landed on piece page first)
      else if ( this.$store.state.hintingHasSelected ) {
        this.hintLineIndex = this.hintLineIndex === 0 ? 1 : 0
      }
      // Hasn't scrolled or selected
      else {
        this.hintLineIndex = this.hintLineIndex === 2 ? 0 : this.hintLineIndex + 1
      }

      // Add a pause between hinting cycles
      if ( this.hintLineIndex === 0 ) {
        clearInterval( this.hintInterval )
        setTimeout( () => {
          this.hintInterval = setInterval( this.updateHint, 600 )
        }, 1800 )
      }
    },
    stopHinting() {
      this.hintLineIndex = 0
      clearInterval( this.hintInterval )
    }
  }
}

</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';
  @import '@/styles/components/mountedPiece.scss';

  .Featured {

    .placement {

      overflow: hidden;

      position: fixed;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      @include breakpoint( mobile ) {
        position: absolute;
      }

    }

    .titles {

      position: absolute;

    }

    .titles {

      left: 0;
      top: 50%;

      user-select: none;
      text-align: center;

      .title-enter-active {
        transition: transform 0.8s 0.1s $ease-out-quart, opacity 0s 0.1s;
      }

      .title-leave-active {
        transition: transform 0.1s $ease-in-quart, opacity 0s 0.1s;
      }

      .title-enter,
      .title-leave-to {
        opacity: 0;
        transform: translate3d(0%, calc( -50% + 1.5vw ), 0 );
      }

      h2 {

        position: absolute;
        left: 0;
        top: 0;

        width: 100vw;
        padding: 0 10vw;
        margin: 0;

        @include fontBlack();
        color: $color-violet;
        font-size: 9vw;
        line-height: 1;
        text-transform: uppercase;

        transform: translate3d(0%, -50%, 0 );

        a {

          display: block;

          text-decoration: none;

        }

      }

    }

    .collection {

      @include mountedPiece();

      z-index: $z-index-1;

      .piece {

        visibility: hidden;

        &.is-active {

          visibility: visible;

        }

      }

    }

    .hint {

      position: fixed;
      left: 25%;
      width: 50%;

      bottom: rem( 38 );

      @media only screen and (min-width: 1680px) {
        bottom: rem( 44 );
      }

      @media only screen and (min-width: 2200px) {
        bottom: rem( 52 );
      }

      @include breakpoint( mobile ) {
        left: 0;
        width: 100%;
        bottom: rem( 30 );
      }

      p {

        position: relative;

        width: 100%;

        text-align: center;

        color: $color-violet;
        @include fontBlack();
        font-size: rem( 16 );
        letter-spacing: 0.01em;
        line-height: 1.4;
        text-transform: uppercase;

        @include breakpoint( mobile ) {

          font-size: rem( 12 );

        }

        span {

          position: absolute;
          left: 0;
          bottom: 0;

          display: block;
          width: 100%;

        }

        .hint-enter-active {
          transition: transform 0.4s 0.1s $ease-out-quart, opacity 0s 0.1s;
        }

        .hint-leave-active {
          transition: transform 0.1s $ease-in-quart, opacity 0s 0.1s;
        }

        .hint-enter,
        .hint-leave-to {
          opacity: 0;
          transform: translate3d(0, rem( 6 ), 0);
        }

      }

    }

  }

</style>
