<template>
  <section class="Home">
    <Featured :isMountingFromPreloader="isMountingFromPreloader" />
  </section>
</template>

<script>

import Featured from '@/components/Featured.vue'

export default {
  name: 'home',
  props: {
    isMountingFromPreloader: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Featured,
  }
}

</script>
