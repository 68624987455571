import { MOBILE_BREAKPOINT } from '@/constants'

export default {
  methods: {
    getMountedPieceDimensions( aspect, scale, viewportWidth, viewportHeight ) {

      // const LANDSCAPE_ASPECT = 297 / 210
      // const aspectMultiplier = aspect === 1 ? 0.75 : Math.min( Math.max( aspect / LANDSCAPE_ASPECT, 0.7 ), 1.1 )

      const scaleMin = viewportWidth <= MOBILE_BREAKPOINT ? 1 : 0

      const width = ( 50 + 50 * Math.max( 1 - viewportWidth / 1440, 0 ) ) * Math.max( scale, scaleMin ) /* * aspectMultiplier */
      const height = width / aspect

      const hInPx = ( height / 100 ) * viewportWidth
      const MIN_H_MARGIN = 120
      const hRatio = Math.max( hInPx / ( viewportHeight - ( MIN_H_MARGIN * 2 ) ), 1 )

      const w = width / hRatio
      const h = height / hRatio

      return {
        w,
        h,
        width: `${ w }vw`,
        height: `${ h }vw`,
        left: `${ w * -0.5 }vw`,
        top: `${ h * -0.5 }vw`
      }
    }
  }
}
