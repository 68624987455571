<template>
  <div class="MountedPieceShadow">
    <canvas v-if="isSafari" ref="canvas" :style="{ filter: `blur(${cssBlurSize}px)` }" />
    <canvas v-else ref="canvas" />
  </div>
</template>

<script>

import viewport from '@/managers/ViewportManager'

import * as Detect from '@/utils/detect'
import { clamp } from '@/utils/math'

export default {
  name: 'MountedPieceShadow',
  props: {},
  components: {},
  data() {
    return {
      isSafari: Detect.safari(),
      resizeId: null
    }
  },
  computed: {
    blurSize() {
      return clamp( Math.round( 15 * ( viewport.latest.width / 1920 ) ), 8, 20 )
    },
    cssBlurSize() {
      return this.isSafari ? this.blurSize : 0
    }
  },
  mounted() {
    this.resizeId = viewport.on( 'resize', () => this.updateCanvas() )

    // Defer to ensure correct blur appearance on mount
    setTimeout( this.updateCanvas, 100 )
  },
  beforeDestroy() {
    viewport.off( this.resizeId )
  },
  updated() {
    this.updateCanvas()
  },
  methods: {
    updateCanvas() {
      const SIZE = 1.2

      const { offsetWidth, offsetHeight } = this.$el

      const width = SIZE * offsetWidth
      const height = SIZE * offsetHeight

      const ctx = this.$refs.canvas.getContext( '2d' )

      ctx.canvas.width = width
      ctx.canvas.height = height

      ctx.clearRect( 0, 0, ctx.canvas.width, ctx.canvas.height )

      ctx.save()
      ctx.translate( ( width - offsetWidth ) / 2, ( height - offsetHeight ) / 2 )
      ctx.fillStyle = '#2c3e50'
      if ( !this.isSafari ) {
        ctx.filter = `blur(${ this.blurSize }px)`
      }
      ctx.fillRect( 0, 0, offsetWidth, offsetHeight )
      ctx.restore()
    }
  }
}
</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  .MountedPieceShadow {

    position: absolute;
    left: 0;
    top: 0;

    display: block;
    width: 100%;
    height: 100%;

    opacity: 0.13;
    transform-origin: center bottom;
    transform: translate3d( 0.667vw, 1.5vw, 0 ) rotate( 0deg ) scale( 0.995 );
    backface-visibility: hidden;

    pointer-events: none;

    canvas {

      position: absolute;
      left: -10%;
      top: -10%;

      width: 120%;
      height: 120%;

      transform: translate3d( 0, 0, 0 );
      backface-visibility: hidden;

    }

  }

</style>
